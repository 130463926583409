<template>
  <div class="list-manage order-details">
    <section class="list-manage-table">
      <div class="table-title">
        <span class="title">订单修改</span>
      </div>
      <section class="order-device-list">
        <div class="device-list-item">
          <!-- <div class="device-list-header">
            <img v-if="orderInfoList.consumer_head == null || orderInfoList.consumer_head == '' " src="~@/assets/images/temp/temp_04.png"  class="avatar"  >
            <img v-else  :src="orderInfoList.consumerHead" class="avatar" alt="" />{{
              orderInfoList.company_name
            }}
          </div> -->
          <div class="device-info-list">
            <div
              class="device-info-item"
              v-for="orderInfo in orderInfoList.order_info"
              :key="orderInfo.order_info_id"
            >
              <img :src="orderInfo.showImg" class="pic" alt="" />
              <div class="content">
                <div class="name">{{ orderInfo.name }}</div>
                <div>
                  <div class="tag">
                    {{ orderInfo.type_name }}
                  </div>
                  <span class="f-orange"
                    >¥{{ orderInfo.rental_fee }}/{{
                      orderInfo.rental_type == 0
                        ? "日"
                        : orderInfo.rental_type == 1
                        ? "月"
                        : orderInfo.rental_type == 2
                        ? "半年"
                        : "年"
                    }}</span
                  >
                </div>
              </div>
              <div class="tar">
                <div>
                  租赁台数
                  <el-input-number
                    v-model="orderInfo.num"
                    size="mini"
                    style="width: 94px"
                    :min="1"
                    :max="orderInfo.max_num"
                    label="描述文字"
                  ></el-input-number>
                </div>
                <div class="mt6">
                  租赁时长（{{
                    orderInfo.rental_type == 0
                      ? "日"
                      : orderInfo.rental_type == 1
                      ? "月"
                      : orderInfo.rental_type == 2
                      ? "半年"
                      : "年"
                  }}）<el-input-number
                    v-model="orderInfo.rental_time"
                    size="mini"
                    style="width: 94px"
                    :min="1"
                    :max="999"
                    label="描述文字"
                  ></el-input-number>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="reserve-info">
        <div class="reserve-info-title">预定信息</div>
        <div class="reserve-info-main">
          <div class="left-area">
            <div class="reserve-info-item">
              <span class="label">项目名称：</span>
              <!-- <span style="padding-right: 25px">{{ list.name }}</span> -->
              <el-input
                  type="text"
                  placeholder="请填写您的项目名称"
                  style="padding-right: 25px"
                  v-model="newlist.name"
                >
                </el-input>
            </div>
            <div class="reserve-info-item">
              <span class="label">用工地址：</span>
              <!-- <span
                >{{ list.address }}<i class="f-icon icon-location-orange"></i
              ></span> -->
              <el-input
                  type="text"
                  id="test"
                  placeholder="请填写您的用工地址"
                  v-model="newlist.address"
                ></el-input>
            </div>
            <div class="reserve-info-item">
              <span class="label">租赁日期：</span>
              <span
                >{{
                  list.rental_time_start
                    ? list.rental_time_start.split(" ")[0]
                    : ""
                }}
                至
                {{
                  list.rental_time_end ? list.rental_time_end.split(" ")[0] : ""
                }}
                <i class="f-icon icon-cal"></i
              ></span>
            </div>
            <div class="reserve-info-item">
              <span class="label">留言备注：</span>
              <el-input
                type="textarea"
                :rows="6"
                placeholder="请填写您的备注内容…"
                v-model="remark"
              >
              </el-input>
            </div>
          </div>
          <div class="map">
            <Map :orderLocation="orderLocation" :unModify="true"></Map>
          </div>
        </div>
      </div>
      <div class="estimate-cost">
        预估费用：<small>¥</small><big>{{ sum }}</big>
        <div class="btn-group">
          <span class="btn" @click="cancel">取消</span
          ><span class="btn btn-orange" @click="apply">立即申请</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getfind, getOrderInfo, apply } from "@/api/over.js";
import { update_order1 } from "../../api/myorder";
import Map from "@/components/map.vue";
import kits from "@/kits/index";
export default {
  data() {
    return {
      textarea: "",
      list: "",
      newlist: "",
      order_id: "",
      order_info_id: "",
      orderInfoList: [],
      oldInfoList: [],
      sum: 0,
      orderLocation: {},
      remark:""
    };
  },
  components: {
    Map,
  },
  watch: {
    orderInfoList: {
      // 监听的对象数组
      handler(newVal) {
        this.sum = 0;
        console.log("watch", newVal);
        if (Array.isArray(newVal.order_info)) {
          newVal.order_info.forEach((item) => {
            item.sum = item.rental_fee * item.num * item.rental_time;
            this.sum += item.sum;
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.order_id = this.$route.query.bigid;
    this.order_info_id = this.$route.query.id;

    this.getlist();
    this.getOrderInfoList();
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    getlist() {
      getfind({ inputs: { order_id: this.order_id } })
        .then((res) => {
          this.list = res.data;

          this.newlist = kits.obj.copy(res.data) ;
          console.log("这是请求的数据===", this.list);
          this.orderLocation = {
            lng: res.data.longitude,
            lat: res.data.latitude,
          };
        })
        .catch((err) => {
          this.$message({
            message: `${err}`,
            type: "error",
          });
        });
    },
    //子订单详情
    getOrderInfoList() {
      getOrderInfo({ inputs: { order_id: this.order_info_id } }).then((res) => {
        console.log("res", res.data);
        res.data.consumerHead = kits.img.url(res.data.consumer_head);
        res.data.order_info.forEach((item) => {
          item.showImg = kits.img.url(item.show_img);
        });
        this.orderInfoList = res.data;
        this.oldInfoList = JSON.parse(JSON.stringify(res.data));
      });
    },
    //申请
    apply() {
      console.log(
        "old ",
        this.oldInfoList.order_info,
        "new ",
        this.orderInfoList.order_info
      );
      let oldData = this.format(this.oldInfoList.order_info , this.list);
      let newData = this.format(this.orderInfoList.order_info, this.newlist);
      if (JSON.stringify(oldData) === JSON.stringify(newData)) {
        this.$message({
          message: "数据未修改",
          type: "warning",
        });
        return;
      }

      let params = {
        order_id: this.$route.query.id,
        seller_consumer_id: this.orderInfoList.seller_consumer_id,
        purchaser_consumer_id: this.orderInfoList.purchaser_consumer_id,
        old_data: oldData,
        new_data: newData,
        modified_order_state: 1,
        remark: this.remark,
        modified_by: parseInt(this.$route.query.modified_by),
      };

      console.log("apply params", params);
      if (
        this.orderInfoList.modified_order_state === 0 ||
        this.orderInfoList.modified_order_state === 4
      ) {
        apply({ inputs: params })
          .then((res) => {
            if (res.status === 200) {
              update_order1({
                inputs: {
                  order_id: this.$route.query.id,
                  modified_order_state: 1,
                },
              })
                .then((res) => {
                  if (res.status === 200) {
                    // this.getOrderInfoList()
                    this.$message({
                      message: `申请成功`,
                      type: "success",
                    });
                    this.$router.push({
                      name: "orderManage",
                    });
                  } else if (res.status === 500) {
                    this.$message({
                      message: `未知错误，请联系客服`,
                      type: "error",
                    });
                  } else {
                    this.$message({
                      message: `申请失败`,
                      type: "error",
                    });
                  }
                })
                .catch((err) => {
                  this.$message({
                    message: `${err}`,
                    type: "error",
                  });
                });
            } else {
              this.$message({
                message: "申请失败",
                type: "error",
              });
            }
          })
          .catch((err) => {
            this.$message({
              message: `${err}`,
              type: "error",
            });
          });
      } else {
        this.$message({
          message: `订单修改申请中，请勿重复申请`,
          type: "warning",
        });
      }
    },
    //整理格式
    format(list,list2) {
      let data = [];
     
      list.forEach((item) => {
        console.log("item", item);
        let obj = {
          order_info_id: item.order_info_id,
          rental_time: item.rental_time,
          num: item.num,
          name: list2.name,
          address: list2.address,
        };
        data.push(obj);
      });
      return data;
    },
  },
};
</script>

<style scoped>
</style>
